import { useEffect } from "react";
import SideBar from "../components/SideBar";
import SideBarSmall from "../components/SideBarSmall";
import WhatsappSmall from "../components/WhatsappSmall";

export default function FirstArticle() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20vh",
        marginBottom: "16vh",
      }}
    >
      <SideBar />
      <SideBarSmall />
      <p className="article-text">
        לפני 10 שנים בני הבכור הגיע לעולם וחוויתי לידה עוצמתית ללא כאב בעזרת
        הכלים של ההיפנוברת'ינג. על ההיפנוברת'ינג שמעתי לראשונה כשפגשתי את אליזבת
        (לידה רכה) בחודש חמישי להריון והיא אמרה לי שהיא בדיוק פותחת קורס הכנה
        ללידה בשיטת KG היפנוברת'ינג ואם ברצוני לחוות לידה טבעית עם הרבה ידע
        ועוצמה אני מוזמנת לבוא ללמוד אצלה. כמובן שהתלבטתי, מה זה אומר בכלל
        היפנוברת'ינג? וזה קצת יקר? אחרי התלבטויות וכשגיליתי שמקבלים החזרים
        מהקופות, אז הנושא של המחיר כבר לא היה אישיו. החלטתי שאני נותנת לנושא
        הזדמנות. החשש העיקרי שלי היה מהכאב בלידה ולא רציתי שום התערבויות ומשככי
        כאבים, לכן בחרתי ללמוד את השיטה כדי לתת לעצמי כלים לחוות את הלידה באופן
        מיטבי, רגוע ובשאיפה ללא כאב. התחלנו את הקורס וגילינו עולם ומלואו, למדנו
        על הפיזיולוגיה של הלידה, על שרירי הרחם, על ההורמונים שמופרשים בלידה, על
        השלבים השונים בלידה. על כוחה של התודעה וכיצד ניתן לרתום את המחשבה לעבודה
        יעילה של הרחם. תרגלתי כבר מחודש שישי את הנשימות, הדימויים וההרפיות כך
        שבלידה עצמה הכול היה באופן אוטומטי, הגוף ידע מה לעשות וקיבלתי באהבה את
        הגלים (הצירים) שקידמו אותי אל עבר התינוק שלי.
      </p>
      <p className="article-text">
        ההיפנוברת'ינג אפשר לי לקבל בהבנה ורוגע שהתינוק לא מגיע בדיוק בסוף שבוע
        40, וזה בסדר גמור אם יצא בשבוע 41+3 כי זה הזמן הנכון והמדויק לו לצאת.
        הנשימות והדימויים נתנו לי כוחות להיות ברוגע בכל הגלים המוקדמים שהם לפני
        הלידה הפעילה, שיכולים להימשך ימים, אצלי אפילו שבועות. אז אומנם בלידה
        הזאת לא קיבלתי את החדר הטבעי שנסעתי בשבילו במיוחד עד כפר סבא, אלא דווקא
        את החדר הכי קטן ללא מקלחת ואפילו גם לא מיילדת בגישה טבעית ועומס של
        יולדות במשמרת. אבל גיליתי שלידה טבעית לא צריכה תפאורה, הכול בתוכנו
        במודעות שלנו ובהתכנסות שלנו ובהכנה שלנו ללידה. הקורס עצמו הפגיש אותי עם
        כל הסיטואציות והתרחישים שיכולים להתרחש בלידה, ולכן באתי בהבנה ובהכלה למה
        שיתרחש בלידה. הלידה עצמה הייתה עם ערוף לאחור (OP) שלרוב נחשבת כואבת יותר
        וארוכה, אך עם הכלים של ההיפנוברת'ינג לא חוויתי את הלידה בכאב כלל, היא
        אומנם לתחושתי הייתה ארוכה יותר אבל בפועל ללידה ראשונה 8 שעות זה לא נחשב
        הרבה זמן. אני יכולה להעיד שבלידות הבאות שנעו בין 10 דקות ל-3 שעות כנראה
        הרגשתי שזה היה ארוך יותר כנראה כי כך היה צריך להיות. אז היום לאחר 10
        שנים וארבע לידות היפנו עוצמתיות ביותר טבעיות ללא כאב והתערבויות, אני
        פותחת בעצמי קורס הכנה ללידה בשיטת KG היפנוברת'ינג באזור מודיעין. כבר
        בספטמבר ואוקטובר. אני אומנם לא יכולה להבטיח לידה ללא כאב, כי אצל כל אחת
        זה אישי בהתאם לגוף שלה, למודעות שלה ולתרגול לקראת הלידה. אבל מי שרוצה
        לידה טבעית, עוצמתית, רכה ומחוברת עם ידע וכלים רבים מוזמנת לפנות אלי. אני
        ממש רוצה לתת את המתנה הזאת לנשים רבות, כדי שיחוו את החיבור לעצמן, ילדו
        כפי שהכי טבעי ונכון להן בעוצמה, רכות ורוגע. מחכה לכן.
      </p>
      <style>
        {`
        .article-text {
            width: 80vw;
       text-align: right; 
        font-size: 2vw;
        }
      
          @media (max-width: 1250px) {
            .article-text {
              width: 80vw;
              font-size: 5vw; /* Adjusted smaller screen font size */
              text-align: right;
            }
          }
        `}
      </style>
      <WhatsappSmall />
    </div>
  );
}

import Header from "./Header";
import styles from "./RefundComp.module.css";

export default function RefundComp() {
  return (
    <div className={styles.container}>
      <Header>החזר על קורס הכנה ללידה רכה היפנובירת’ינג</Header>

      <p>
        ייתכן ואת זכאית לקבל החזר על קורס הכנה ללידה! מומלץ לבדוק את כל
        האפשרויות הבאות. קופת החולים (ביטוח משלים) ביטוח פרטי בית חולים לקבלת
        החזר על קורס הכנה ללידה פרטי מקופת חולים, ביטוח פרטי או בית חולים יתכן
        שתדרשי להגיש עם התביעה את המסמכים הבאים :
      </p>
      <ul>
        <li> קבלה או חשבונית על תשלום לקורס</li>
        <li>אישור עוסק פטור של המדריכה</li>
        <li>תעודת הסמכה של המדריכה</li>
      </ul>

      <p>
        אני אוכל להמציא לך קבלה/ חשבונית, תיעוד עוסק פטור וכן תעודת הסמכה
        כמדריכת לידה רכה. ההכשרה של מדריכות לידה רכה מוכרת על ידי קופות החולים
        וכן על ידי ארגון המיילדות הבריטי RCM. אין באמור התחייבות כלשהיא מצד לידה
        רכה וכל התנאים תלויים בתנאים של מבטח או בית החולים ובכפוף לשנויים
        המשתנים מעת לעת. מומלץ בכל מקרה לבדוק מול המבטח את הפרטים המדויקים של
        ההחזרים העומדים לרשותך. החזר מבית חולים על קורס הכנה ללידה פרטי בית
        חולים שערי צדק וביקור חולים מעניקים לך החזר על סך 600 ש”ח לקורס הכנה
        ללידה פרטי, ליולדות שם. בית חולים הדסה עין כרם ובית חולים הדסה הר הצופים
        מעניקים לך החזר ע”ס 500 ש”ח על קורס הכנה ללידה פרטי, ליולדות שם. לניאדו
        וגם מעייני הישועה מעניקים לך החזר של 250 ש”ח על קורס הכנה ללידה פרטי. יש
        לברר את התנאים המדוייקים מול בית החולים.
      </p>

      <h2>החזר קורס הכנה ללידה – קופת חולים</h2>
      <h2>מכבי</h2>
      <p>
        מבוטחות מכבי שלי זכאיות להנחה של 75% הנחה על קורס הכנה ללידה ועד גובה
        1000 ש”ח. לא יתקבל החזר על: מפגש חד פעמי קורס שאינו בממשק דו כיווני קורס
        טלפוני הנחיות במייל או בדיסק נדרש ותק של 6 חודשים כמובטחת מכבי שלי.
        למידע נוסף יש לבדוק עם הקופה 3555* 1700-50-53-53
      </p>
      <h2>כללית</h2>
      <p>
        מבוטחות כללית פלטינום זכאיות להנחה של 75% על קורס הכנה ללידה ועד גובה
        1000 ש”ח. מאחר והמידע לא מופיע באתר של כללית אנו ממליצות לך להתקשר
        לבירור מול קופת החולים. למידע נוסף יש לבדוק עם הקופה בטלפון 2700*
      </p>
      <h2>לאומית</h2>
      <p>
        לאומית זהב- קורס הכנה ללידה פרונטלי בלבד, החל משבוע 28, 80% החזר עד תקרה
        של 333 ש”ח לאומית כסף- קורס הכנה ללידה פרונטלי בלבד, החל משבוע 28, 80%
        החזר עד תקרה של 267 ש”ח לאומית זהב לנשים עם תוספת חבילת הריון ולידה– 75%
        החזר עד תקרה של 2009 ש”ח לבירור נוסף מול קופת חולים לאומית 1700-507-507
        507*
      </p>
      <h2>מאוחדת</h2>
      <p>
        לקוחות “שיא” יקבלו החזר של עד 50% ועד תקרה של 508 ש”ח. זכאות להחזר היא
        בעבור סדנה שהועברה על-ידי אחות מוסמכת/מיילדת/דולה במסגרת הקופה ו/או אצל
        ספקים הקשורים עמה בהסכם. לבירור נוסף אנא פנו לקופת חולים מאוחדת 3833* או
        077-2703716 מחלקת ביטוחים של קופת חולים מאוחדת: 03-5202323
      </p>
      <h2>הקורסים של לידה רכה היפנובירתינג</h2>
      <p>
        הקורסים מועבר על ידי מדריכת לידה רכה באזור מודיעין לרב מכוסים על ידי
        קופות החולים ובתי החולים המעניקים שירות זה, וזאת בכפוף לתנאים המשתנים
        המוכתבים על ידם: אין באמור התחייבות כלשהי וכל התנאים תלויים בתנאים של
        קופות החולים המשתנים מעת לעת. בכל שאלה נוספת דברי איתנו
      </p>
    </div>
  );
}

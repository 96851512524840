import styles from "./Images.module.css";
import firstrecommendation from "../recommendation-1.jpg";
import secondRecommendation from "../recommendation-2.jpg";
import thirdRecommendation from "../recommendation-3.jpg";
export default function Images() {
  return (
    <div className={styles.container}>
      <img alt="" src={firstrecommendation} />
      <img alt="" src={secondRecommendation} />
      <img alt="" src={thirdRecommendation} />
    </div>
  );
}

import styles from "./Questions.module.css";

export default function Questions() {
  return (
    <div className={styles.container}>
      <h1>למה לבחור בkg היפנובירת'ינג</h1>
      <p>
        <strong>
          היתרונות העיקריים של ההיפנוברת'ינג הם בכך שהיא מסייעת לחוויית לידה
          חיובית ונעימה יותר, לאם, לאב ולתינוק .
        </strong>
      </p>
      <p>
        במהלך הקורס אנו עובדים על שינוי תבניות חשיבה שליליות לתבניות חשיבה
        חיוביות, עובדים על שחרור פחדים ושינוי לדפוסי חשיבה חיוביים. כל אלו
        מאפשרים לאם לצפות ללידה מעצימה, רגועה ויעילה, האם מקבלת כלים לעבודה עם
        התודעה דרך נשימות ודמיון מודרך, הכנה רבה במהלך ההריון כך שתאפשר ללידה
        להתרחש באופן אוטומטי ללא חשיבה מודעת.
      </p>
      <p>
        לאב הקורס נותן כלים ומאפשר לו מעורבות רבה יותר בלידה, הבנת תהליך הלידה
        ותמיכה באם באופן מיטבי במהלכה. לתינוק היתרון העיקרי בכך שהוא נכנס לעולם
        בצורה רכה ונעימה יותר, דבר שמקנה לו התחלה רגועה יותר להמשך החיים.
      </p>
      <h1>כמה עולה הקורס?</h1>
      <p>
        קורס קבוצתי עולה 1400 ש”ח- למציאת קורס באזור מגורייך בקרי{" "}
        <a href="https://galmir123.github.io/dina-app/#/calendar">
          בלוח הקורסים
        </a>
      </p>
      <p>
        קורס אישי בביתך ייקבע בהתאם למרחק מביתך ונע בסביבות 2500 ש”ח.{" "}
        <a href="https://galmir123.github.io/dina-app/#/contact">
          ליצור איתי קשר
        </a>
      </p>

      <h1>מה אלמד בקורס?</h1>

      <ul>
        <li>תרגילים לנשימות בלידה</li>
        <li>טכניקות להרגעות בלידה</li>
        <li>דימיון מודרך</li>
        <li>בניית הבטחון האישי שלך בהריון ובלידה</li>
        <li>כיצד בן הזוג יכול לסייע בתמיכה בך והגנה עליך</li>
        <li>כיצד גופך בנוי ללדת בצורה יעילה ונוחה</li>
        <li>מידע אודות ההריון והלידה שסביר להניח שלא תשמעי במקום אחר</li>
        <li>
          כיצד המערכת בה את נמצאת פועלת וכיצד לעבוד עם המערכת להשגת הוצאה הטובה
          ביותר עבורך
        </li>
        <li>תוכנית תרגול פשוטה לתמיכה בתרגול העצמאי בביתך</li>
        <li>הבנה כיצד גופך ומוחך פועלים יחד</li>
        <li>למדי על 5 העקרונות של לידה רכה:</li>
      </ul>

      <ol>
        <li>
          {" "}
          בטחון– כשאת חשה בטוחה, את נרגעת ועובדת עם גופך באופן שבו הטבע התכוון
        </li>
        <li>שליטה– הידע שאת מקבלת מאפשר לך לרכוש שליטה על האופן בו את יולדת</li>
        <li>
          בחירה– כשאת חשה בשליטה, הבחירות שאת מקבלת הן הבחירות הטובות ביותר לך
          ולתינוקך
        </li>
        <li>
          רוגע– כשאת רגועה לאורך כל הלידה, את רפויה, גופך ומוחך פועלים יחד
          בהרמוניה
        </li>
        <li>נוחות– התוצאה היא שגופך עובד באופן יעיל והרמוני</li>
      </ol>
      <p>לידה רכה יכולה להיות החוויה המדהימה והמעצימה ביותר בחייך!</p>

      <h1>אלו חומרי לימוד אקבל?</h1>
      <p>
        עם ההרשמה והתשלום לקורס את מקבלת את האלבום להרפיה בהריון ובלידה “צבעים
        ורוגע” (קובץ שמע המגיע למייל), על מנת שתוכלי להתחיל בתרגול ולפתח גישה
        חיובית להריון וללידה באופן מיידי. עם פתיחת הקורס תקבלי ספר בעברית, אשר
        תורגם על ידי אליזבת ברנט וגם חוברת תרגול אשר תומכת בחומר הנלמד במהלך
        הקורס ותסייע לך בהריונך ובלידת תינוקך
      </p>
      <h1>האם גם בן הזוג צריך להיות נוכח בקורס?</h1>
      <p>
        הוא לא “צריך” להשתתף בקורס, אך הוא ישמח מאד אם יחליט להשתתף. רב האבות
        מגיעים לקורס מלאי סקפטיות ומסיימים את הקורס חדורי מוטיבציה. אין לנו
        ציפיה שתאמין לזה. למה שתאמין? אנו כן מבקשים ממך להקשיב לעובדות ולהגיון
        מאחורי הדברים כדי שתבין עד כמה זה חשוב וכדי שתבין את ההשפעה העצומה
        שיכולה להיות לך על הלידה. הקריקטורה של אב בלידה הינה של אדם חרד, החש
        אחריות למצב אליו הביא אותה, אוחז בידה וחושב לעצמו כמה היה רוצה לעזור לה
        אך הוא לא יודע איך. אב שהכין עצמו ללידה רכה הינו בעל ידע ומסוגל לתמוך בה
        בזמן שהיא יולדת. הוא נכס עצום בלידה ואמהות רבות אומרות “לא הייתי מסוגלת
        בלעדיו”. הם התכוננו יחד לפני הלידה, דבר המקרב אותם זה לזו. הוא יודע שיש
        לו חלק נכבד באופן שבו התינוק מגיע לעולם. זה מעמיק את מערכת היחסים של בני
        זוג וגם את הקשר שלו עם התינוק.
      </p>
      <h1>האם הקורס יעזור אם אגיע לבד?</h1>
      <p>
        כן, בהחלט. נשים רבות הגיעו לקורס לבדן, אם בן זוגן לא נמצא בארץ, אמהות
        יחידניות, אם אין להם בייביסיטר לפעוט בבית או כל סיבה אחרת. הקורס משמעותי
        מאד לשני בני הזוג ואתם מוזמנים להצטרף. אם בן זוגך לא יכול להצטרף, את
        מוזמנת להגיע עם אימך, חברה או כל אדם אחר שיתמוך בך בלידה.
      </p>
      <h1>האם עלות הקורס מכסה את שנינו?</h1>
      <p>
        כן, אין תשלום נוסף לאב המשתתף בקורס ורב האבות בוחרים להצטרף כדי לתמוך
        בבת זוגם.
      </p>

      <h1>מתי מומלץ להתחיל את הקורס?</h1>

      <p>
        אם יש לך אפשרות לבחור, הייתי ממליצה לך להתחיל בטרימסטר השני להריונך, כדי
        שתוכלי ליהנות מהיתרונות שהקורס מעניק לך גם בהריון, בנוסף ללידה עצמה. לא
        מדובר בשינון עובדות שצריכות להיות טריות במוחך לקראת הלידה, אלא יותר
        בספיגת המידע שיהפוך לטבע שני עבורכם. בכל מקרה הקורס יסייע בכל זמן
        שתבחרו. אם תשתתפו בקורס מוקדם יותר, תהני מיתרונותיו של הקורס במהלך
        ההריון בנוסף ללידת תינוקך.{" "}
      </p>

      <h1>מה אם תאריכי הקורס לא מסתדרים לי?</h1>

      <p>
        קיימת אפשרות לקביעת קורס פרטי בביתך במועדים הנוחים לך. כמו כן, אני אעשה
        הכל כדי להתאים את הקורס לצרכייך. מחשבים הם כלי נפלא, אך הם מתקשים
        בגמישות…לכן את מוזמנת להתקשר אליי ונמצא יחד פתרון. אני יודעת עד כמה
        השתתפות בקורס יכולה להיות משמעותית עבורך ולכן אשתדל שתיהני מהקורס המדהים
        הזה. בנוסף קיימת אפשרות לעבור קורס אונליין בעברית לצפייה ותרגול בזמן שנח
        לכם, דרך אתר לידה רכה.
      </p>

      <h1>כמה זוגות משתתפים בקורס?</h1>

      <p>
        אם את מעדיפה מפגש אחת על אחת, קיימת אפשרות לקיים את הקורס באווירה
        אינטימית בביתכם. גודלם של הקבוצות משתנה בהתאם לאזור בארץ, יום, שעה ועוד
        גורמים הנסתרים מעינינו… רב הקבוצות מורכבות מ-5 עד 7 זוגות.
      </p>

      <h1>מהי פריסת השיעורים בקורס של לידה רכה?</h1>
      <p>
        קיימות מספר פריסות אפשריות להדרכת הקורס, בניהן פריסה ל-5 מפגשים, 4
        מפגשים או 2 מלאים בסוף שבוע אחד או בשני סופי שבוע. לכל פריסת שעורים יש
        יתרונות וחסרונות אך לא ניתן להצביע על פריסה שהוכחה מחקרית כיעילה יותר.
        בכל מקרה, לידה רכה משפיעה באופן מופלא אז תבחרו בפריסה שהכי נוחה לכם. רב
        הקורסים של לידה רכה מועברים בפריסה של 4 מפגשים. בכל מקרה, בכל קורס
        שתבחרי את תקבלי את כל החומר והידע הנדרשים ללידה שלך.
      </p>
      <h1>אם אני מתכננת לידת בית חולים זה יכול לעבוד?</h1>

      <p>
        כן, בהחלט. ישנן נשים רבות שלומדות בקורס של לידה רכה ויולדות לידה בבית
        חולים, בית יולדות או מרכזי לידה.
      </p>

      <h1>אם אין לי ניסיון בדמיון מודרך ומדיטציות?</h1>

      <p>
        בקורס את רוכשת את כל המיומנויות הנדרשות לדמיון מודרך והרפיה. מי שמתקשה
        מקבלת הדרכה אישית המעמיקה את היכולת להרפות וליהנות מיתרונות הדמיון
        המודרך. הקורס של לידה רכה מאד מובנה ומסודר. כל החומר מבוסס מדעית ואתם
        מיד תבחינו שמדובר בשיטה מאד לוגית ופרקטית שיכולה להתאים גם ללא ניסיון
        קודם.
      </p>

      <h1>האם הקורס מתאים רק לאנשים רוחניים?</h1>
      <p>
        לא . הקורס מיועד לכל בני זוג המעוניינים בלידה רכה ונוחה לעצמם ולתינוקם.
        בקורסים מגוון רחב של אנשים מרקעים שונים. המשותף לכל האמהות, היא רצונן
        להשקיע כדי לשפר את חווית הלידה עבורן ועבור תינוקן.
      </p>
    </div>
  );
}

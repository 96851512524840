import styles from "./ContactDetails.module.css";

export default function ContactDetails() {
  return (
    <div className={styles.container}>
      <p> אימייל: dinalevitan123@gmail.com </p>
      <p> מספר טלפון: 0503500223</p>
    </div>
  );
}
